// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labrador-js": () => import("./../../../src/pages/labrador.js" /* webpackChunkName: "component---src-pages-labrador-js" */),
  "component---src-pages-pinscher-js": () => import("./../../../src/pages/pinscher.js" /* webpackChunkName: "component---src-pages-pinscher-js" */),
  "component---src-pages-pitbull-js": () => import("./../../../src/pages/pitbull.js" /* webpackChunkName: "component---src-pages-pitbull-js" */),
  "component---src-pages-poodle-js": () => import("./../../../src/pages/poodle.js" /* webpackChunkName: "component---src-pages-poodle-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-quizz-js": () => import("./../../../src/pages/quizz.js" /* webpackChunkName: "component---src-pages-quizz-js" */),
  "component---src-pages-remove-js": () => import("./../../../src/pages/remove.js" /* webpackChunkName: "component---src-pages-remove-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-termo-js": () => import("./../../../src/pages/termo.js" /* webpackChunkName: "component---src-pages-termo-js" */)
}

