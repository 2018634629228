module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","sm":"(max-width: 720px)","md":"(max-width: 1024px)","l":"(max-width: 1536px)","xl":"(max-width: 4000px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1397751467061861"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-162518679-1","AW-973233216"],"gtagConfig":{"optimize_id":"GTM-NJZF2H5","anonymize_ip":false,"cookie_expires":63072000},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Urdog","short_name":"Urdog","icon":"src/img/icon.png","start_url":"/","background_color":"#FFF","theme_color":"#F19302","display":"standalone","description":"A coleira inteligente que captura dados da vida do seu cachorro e usa inteligencia artificial para ajuda você a entender melhor o seu melhor amigo","lang":"pt-br","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a2f7432859b8f80b775f6ad1f19d77a0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
